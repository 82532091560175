import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import VueRouter from 'vue-router';
import router from './routes.js';
import Auth from './auth.js';
import Api from './api.js';
import Locale from './locale.js';
import Vue from 'vue';//CCI added fix

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.moment = require('moment');
require("../vendor/tempusdominus/tempusdominus.min");

window.Vue = require('vue').default;  
//window.Vue = new Vue;//CCI
window.Event = new Vue;
window.api = new Api();
window.auth = new Auth();

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.prototype.$auth = window.auth;
Vue.prototype.$api = window.api;
Vue.prototype.$notify = function(message, type = 'info', timeout = 5000) {
    Event.$emit('notify', {message: message, type: type, timeout: timeout});
};

const store = new Vuex.Store({
    modules: {
        i18n: vuexI18n.store
    }
});

Vue.use(vuexI18n.plugin, store);

import es from './translations/es.js'

Vue.i18n.add('es', es);

window.locale = new Locale();
Vue.prototype.$locale = window.locale;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components');

var mixin = {
};

Vue.mixin(mixin);

const app = new Vue({
    el: '#app',
    router
});
