<template>
    <div class="email-message-input">
        <ckeditor v-model="data" v-on:ckeditor="setCKEditor" v-on:input="onChange" :isTranslation="isTranslation"></ckeditor>
        <select @click.stop.prevent="" v-model="variable" @change="changeVariable" class="form-control btn-info">
            <option value="" disabled="disabled">Insert email variable...</option>
            <optgroup v-for="(vars, group) in variables" :label="group">
                <option v-for="v in vars" :value="v.value" :title="v.label">{{v.name}}</option>
            </optgroup>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'email-message',
        data() {
            return {
                data: "",
                variable: "",
                bookmark: {},
                variables: {
                    "User": [
                        {'name': "Name Prefix", 'value': "[name_prefix]", 'label': "Replaced with name prefix."},
                        {'name': "First Name", 'value': "[name_first]", 'label': "Replaced with first name"},
                        {'name': "Middle Name", 'value': "[name_middle]", 'label': "Replaced with middle name"},
                        {'name': "Last Name", 'value': "[name_last]", 'label': "Replaced with last name"},
                        {'name': "Name Suffix", 'value': "[name_suffix]", 'label': "Replaced with name suffix."},
                        {'name': "Username", 'value': "[username]", 'label': "Replaced with username"},
                        {'name': "User ID#", 'value': "[user_id]", 'label': "Replaced with user ID number"},
                    ],
                    "Custom": []
                },
            }
        },
        computed: {
            translation() {
                if(this.$i18n.locale() == 'en')
                {
                    return "English Translation";
                }
                else
                {
                    return "Spanish Translation";
                }
            }
        },
        props: {
            value: {default: ""},
            isTranslation: {default: true},
            is_variable: {default: false},
        },
        watch: {
            value(newVal, oldVal)
            {
                this.data = newVal;
            }
        },
        methods: {
            changeVariable() {
                switch(this.variable)
                {
                    default:
                        this.inject(this.variable);
                }
                this.variable = ""
            },
            onChange(args) {
                this.data = args;
                this.$emit('input', args);
            },
            setCKEditor(ckeditor) {
                this.ckeditor = ckeditor;
            },
            inject(value)
            {
                this.ckeditor.insertText(value);
            },
            refresh() {
                api.call('get','/api/email/variable').then(response => {
                    this.variables["Custom"] = [];
                    response.data.variables.forEach(v => {
                        this.variables["Custom"].push({
                            value: '[' + v.variable + ']',
                            name: v.title,
                        });
                    });
                });
            }
        },
        mounted() {
            this.data = this.value;
            if(!this.is_variable)
                this.refresh();
            else
                delete this.variables["Custom"];
        },
    }
</script>
