<template>
    <div class="app-component">
        <div v-if="environmentState == Environment.development" class="fixed-top">
            <div class="bg-primary pt-1 pb-1"></div>
            <div class="bg-primary text-white text-center dev-marque cursor-default" data-toggle="tooltip" data-placement="bottom" v-bind:title="'MIX_APP_ENV = '+ environment">Deployed For Development</div>
        </div>
        <div v-else-if="environmentState == Environment.other" class="fixed-top">
            <div class="bg-danger pt-1 pb-1"></div>
            <div class="bg-danger text-white text-center dev-marque cursor-default" data-toggle="tooltip" data-placement="bottom" v-bind:title="'MIX_APP_ENV = '+ environment">Check Envronment Variable</div>
        </div>        
        <nav class="navbar navbar-expand-md navbar-dark bg-dark" v-if="!$route.meta.fullPage">
            <router-link class="navbar-brand" to="/">DataVine</router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarsExampleDefault">
                <ul class="navbar-nav mr-auto">
                    <li v-if="$auth.allowed('conference')" class="nav-item"><router-link class="nav-link" to="/conference">Conferences</router-link></li>
                    <li v-if="$auth.allowed('district')" class="nav-item"><router-link class="nav-link" to="/district">Districts</router-link></li>
                    <li v-if="$auth.allowed('church')" class="nav-item"><router-link class="nav-link" to="/church">Churches</router-link></li>
                    <li v-if="$auth.allowed('group')" class="nav-item"><router-link class="nav-link" to="/group">Groups</router-link></li>
                    <li v-if="$auth.allowed('people')" class="nav-item"><router-link class="nav-link" to="/person">People</router-link></li>
                    <li v-if="$auth.allowed('email')" class="nav-item"><router-link class="nav-link" to="/email/compose">Email</router-link></li>
                    <li v-if="$auth.allowed('mailing')" class="nav-item"><router-link class="nav-link" to="/mailing">Mailing</router-link></li>
                    <li v-if="$auth.allowed('report')" class="nav-item"><router-link class="nav-link" to="/report/canned">Reports</router-link></li>
                </ul>
                <ul class="navbar-nav mr-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="locale" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ ($locale.get() === 'en')?'English':'Español' }}</a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="locale">
                            <a @click="$locale.set('en')" href="#en" class="dropdown-item">English</a>
                            <a @click="$locale.set('es')" href="#es" class="dropdown-item">Español</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown" v-if="$auth.allowed('admin')">
                        <a class="nav-link" href="#" id="adminmenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-gear"></i></a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="adminmenu">
                            <!--router-link class="dropdown-item" to="/admin/roles">Roles</router-link-->
                            <router-link class="dropdown-item" to="/user">Users</router-link>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav mr-0 ml-2">
                    <li class="nav-item dropdown" v-if="authenticated && user">
                        <a class="nav-link" href="#" id="usermenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img v-if="user.icon" :src="user.icon" class="user-icon rounded-circle"/><div v-else="" class="user-icon rounded-circle bg-light text-dark text-center"><i class="fa fa-user"></i></div></a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="usermenu">
                            <div class="dropdown-item-text">{{user.name}}</div>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" to="/change-password">Change Password</router-link>
                            <router-link class="dropdown-item" to="/profile">Update Profile</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" to="/logout">Logout</router-link>
                        </div>
                    </li>
                    <li class="nav-item" v-else>
                        <router-link class="nav-link" to="/login">Login</router-link>
                    </li>
                </ul>
            </div>
        </nav>

        <div :class="{'mt-3': !$route.meta.fullPage, 'page-content': true, 'full-page': $route.meta.fullPage, 'container-fluid': !$route.meta.fullPage}">
            <router-view></router-view>
        </div>

        <div v-if="loading" class="text-center app-loading-mask"><i class="fa fa-spinner fa-spin fa-4x"></i></div>
        <notify></notify>
        <div v-if="environmentState == Environment.development" class="bg-primary pt-1 pb-1 fixed-bottom"></div>
        <div v-else-if="environmentState == Environment.other" class="bg-danger pt-1 pb-1 fixed-bottom"></div>
    </div>
</template>

<script>
    export default {
        components: {notify: require('./Widget/Notify').default},
        data() {
            return {
                environmentState: null,
                environment: "",
                authenticated: auth.check(),
                user: auth.user,
                loading: api.load > 0,
                Environment: {
                    other:0,
                    development:1,
                    production:2,
                }
            };
        },
        mounted() {
            this.environmentState = this.Environment.other;
            this.environment = process.env.MIX_APP_ENV

            if(this.environment == undefined){
                this.environment = "missing";
            } else {
                var $cleanEnv = this.environment.toLowerCase().trim();

                if ($cleanEnv == 'local' || $cleanEnv == 'development') {
                    this.environmentState = this.Environment.development;
                }
                else if ($cleanEnv == 'production') {
                    this.environmentState = this.Environment.production;
                } 
                else if ($cleanEnv == "") {
                    this.environment = "not defined";
                }
            }

            Event.$on('userLoggedIn', () => {
                this.authenticated = true;
                this.user = auth.user;
                this.$locale.update(this.user.language);
            });

            Event.$on('userUpdated', () => {
                this.user = auth.user;
            });

            Event.$on('userLoggedOut', () => {
                this.authenticated = false;
                this.user = null;

                window.location = '/login';
            });

            Event.$on('startLoading', () => {
                this.loading = true;
            });

            Event.$on('stopLoading', () => {
                this.loading = false;
            });
        },
    }
</script>