class Locale {
    constructor() {
        this.locale = window.localStorage.getItem('locale');

        if (!this.locale)
        {
            this.update('en');
        }
        else
        {
            this.update(this.locale);
        }
    }

    set (locale) {
        this.update(locale);
        window.location = window.location;
    }

    update (locale) {
        this.locale = locale;

        window.localStorage.setItem('locale', this.locale);

        window.axios.defaults.headers.common['Accept-Language'] = this.locale;
        window.Vue.i18n.set(this.locale);
    }

    get () {
        return this.locale;
    }

}

export default Locale;