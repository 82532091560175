
// Vue.component('app', require('./components/App.vue').default);
// Vue.component('ckeditor', require('./components/Input/CKEditor').default);
// Vue.component('email-message', require('./components/Input/EmailMessage').default);
// Vue.component('datetime', require('./components/Input/Datetime').default);
// Vue.component('tinput', require('./components/Input/Tinput').default);
// Vue.component('ttextarea', require('./components/Input/Ttextarea').default);

import DVapp from './components/App.vue';
import DVckeditor from './components/Input/CKEditor';
import DVemailMessage from './components/Input/EmailMessage';
import DVdatetime from './components/Input/Datetime';
import DVtinput from './components/Input/Tinput';
import DVttextarea from './components/Input/Ttextarea';

Vue.component('app', DVapp);
Vue.component('ckeditor', DVckeditor);
Vue.component('email-message', DVemailMessage);
Vue.component('datetime', DVdatetime);
Vue.component('tinput', DVtinput);
Vue.component('ttextarea', DVttextarea);
