<template>
    <div class="input-group">
        <div class="input-group-prepend flag" :title="translation"><span class="input-group-text"><img :src="'/img/flag/' + $i18n.locale() + '.png'" /></span></div>
        <input type="text" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" class="form-control" />
    </div>
</template>

<script>
    export default {
        name: 'tinput',
        props: ['value'],
        computed: {
            translation() {
                if(this.$i18n.locale() == 'en')
                {
                    return "English Translation";
                }
                else
                {
                    return "Spanish Translation";
                }
            }
        },
    }
</script>

<style>
    .input-group-addon.flag > img {
        margin-top: -2px;
    }
</style>

