<template>
    <div class="notify w-100 mt-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-3 offset-sm-9">
                    <transition v-for="(n, i) in notifications" :key="i" name="slide-fade" appear>
                        <div :class="'alert alert-' + n.type">
                            <span v-html="n.message"></span>
                            <button type="button" class="close" aria-label="Close" @click="dismiss(i)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'notify',
        props: {},
        data: function () {
            return {interval: null, notifications: []};
        },
        computed:{
        },
        watch: {
        },
        components: {},
        methods: {
            addNotification(args)
            {
                var timeout = 5000;
                var type = 'info';

                if(typeof args.timeout !== 'undefined')
                {
                    timeout = args.timeout;
                }

                if(typeof args.type !== 'undefined')
                {
                    type = args.type;
                }

                if(timeout !== 0)
                {
                    args.timeout = Date.now() + timeout;
                }

                args.type = type;

                this.notifications.push(args);
            },
            cleanup()
            {
                for(var i in this.notifications)
                {
                    if(this.notifications[i].timeout !== 0 && this.notifications[i].timeout < Date.now())
                    {
                        this.dismiss(i);
                    }
                }
            },
            dismiss(i)
            {
                this.notifications.splice(i, 1);
            }
        },
        mounted()
        {
            Event.$on('notify', this.addNotification);
            this.interval = setInterval(this.cleanup, 100);
        },
        beforeDestroy()
        {
            clearInterval(this.interval);
        }
    }
</script>

<style>
    .notify {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1001;
        opacity: 0.9;
        pointer-events: none;
    }

    .notify .alert {
        pointer-events: auto;
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-active {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
