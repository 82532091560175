<template>
    <div :id="id"></div>
</template>

<script>
    export default {
        name: 'datetime',
        data() {
            return {
                datetime: null,
                id: null,
            }
        },
        computed: {
            translation() {
                if(this.$i18n.locale() == 'en')
                {
                    return "English Translation";
                }
                else
                {
                    return "Spanish Translation";
                }
            }
        },
        props: {
            value: {default: null},
            format: {default: "YYYY-MM-DD HH:mm:ss"},
            locale: {default: null},
            inline: {default: true},
        },
        watch: {
            value(newVal, oldVal)
            {
                this.datetime.datetimepicker({locale: this.locale, format: this.format, defaultDate: newVal, inline: this.inline, sideBySide: true});
            }
        },
        methods: {
            changed(e)
            {
                this.$emit('input', e.date.format(this.format));
            },
        },
        mounted() {
            this.id = function guid() {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1);
                }
                return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                    s4() + '-' + s4() + s4() + s4();
            }();
            this.$nextTick(() => {
                this.datetime = $('#' + this.id);
                this.datetime.datetimepicker({locale: this.locale, format: this.format, defaultDate: this.value, inline: this.inline, sideBySide: true});
                this.datetime.on('change.datetimepicker', this.changed);
            });
        },
        beforeDestroy() {
        }
    }
</script>
