class Api {
    constructor () {
        this.load = 0;
    }

    call (requestType, url, data = null, config = null) {
        let my = this;
        return new Promise((resolve, reject) => {
            my.load++;
            if(my.load)
            {
                window.Event.$emit('startLoading');
            }
            axios[requestType](url, data, config)
                .then(response => {
                    my.load--;
                    if(!my.load)
                    {
                        window.Event.$emit('stopLoading');
                    }
                    resolve(response);
                })
                .catch(({response}) => {
                    my.load--;
                    if(!my.load)
                    {
                        window.Event.$emit('stopLoading');
                    }
                    if (response.status === 401) {
                        if(url !== '/api/auth/logout')
                        {
                            auth.logout();
                        }
                    }
                    else if (response.status === 422) {
                        if (typeof response.data === 'object') {
                            if(response.data.errors === undefined)
                            {
                                Event.$emit("notify", {
                                    message: response.data.message,
                                    type: "danger",
                                    timeout: 5000,
                                    escape: true
                                });
                            }
                            else
                            {
                                for (let field in response.data.errors) {
                                    if (response.data.errors.hasOwnProperty(field)) {
                                        response.data.errors[field].forEach(message => {
                                            Event.$emit("notify", {
                                                message: message,
                                                type: "danger",
                                                timeout: 5000,
                                                escape: true
                                            });
                                        });
                                    }
                                }
                            }
                        }
                    }
                    else if(response.status === 500)
                    {
                        Event.$emit("notify", {
                            message: "An error occurred.",
                            type: "danger",
                            timeout: 5000,
                            escape: true
                        });
                    }

                    reject(response);
                });
        });
    }
}

export default Api;