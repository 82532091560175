class Auth {
    constructor() {
        this.token = window.localStorage.getItem('token');

        if (this.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;

            this.getUser();
        }
    }

    login (token, user) {
        window.localStorage.setItem('token', token);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        this.token = token;
        this.user = user;

        Event.$emit('userLoggedIn');
    }

    logout() {
        window.localStorage.removeItem('token');
        delete this.token;
        delete this.user;

        return api.call('post', '/api/auth/logout').then(response => {
            delete axios.defaults.headers.common.Authorization;

            Event.$emit('userLoggedOut');
        }).catch(reason => {
            Event.$emit('userLoggedOut');
        });
    }

    check () {
        return !! this.token;
    }

    allowed(permission) {
        let result = !! this.token && !! this.user && (!! this.user.permissions[permission] || !! this.user.permissions['admin']);
//        console.log(permission, result, this.user.permissions);
        return result;
    }

    getUser() {
        api.call('get', '/api/auth/user')
            .then((response) => {
                this.user = response.data;
                Event.$emit('userUpdated');
            });
    }
}

export default Auth;