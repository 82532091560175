import VueRouter from 'vue-router';

let routes = [
    {
        path: '/',
        component: ()=> import('./components/Home')
    },
    {
        path: '/login',
        component: ()=> import('./components/Auth/Login'),
        meta: {fullPage: true},
    },
    {
        path: '/logout',
        component: ()=> import('./components/Auth/Logout'),
    },
    {
        path: '/change-password',
        component: ()=> import('./components/Auth/ChangePassword'),
    },
    {
        //name: 'email',
        path: '/email',
        component: ()=> import('./components/Email/Email'),
        meta: {},
        children: [
            {
                path: '/',
                redirect: 'compose'
            },
            { name: 'email.template', path: 'template/:id', component: ()=> import('./components/Email/Template'), props: true, meta: {breadcrumb: 'Template'} },
            { name: 'email.templates', path: 'template', component: ()=> import('./components/Email/Templates'), meta: {breadcrumb: 'Templates'} },
            { name: 'email.variable', path: 'variable/:id', component: ()=> import('./components/Email/Variable'), props: true, meta: {breadcrumb: 'Variable'} },
            { name: 'email.variables', path: 'variable', component: ()=> import('./components/Email/Variables'), meta: {breadcrumb: 'Variables'} },
            { name: 'email.message', path: 'message/:id', component: ()=> import('./components/Email/Message'), props: true, meta: {breadcrumb: 'Message'} },
            { name: 'email.messages', path: 'message', component: ()=> import('./components/Email/Messages'), meta: {breadcrumb: 'Messages'} },
            { name: 'email.compose', path: 'compose', component: ()=> import('./components/Email/EasyMailer'), meta: {breadcrumb: 'Easy Mailer'} },
            { name: 'email.group', path: 'group/:id', component: ()=> import('./components/Email/Group'), props: true, meta: {breadcrumb: 'Group'} },
            { name: 'email.groups', path: 'group', component: ()=> import('./components/Email/Groups'), meta: {breadcrumb: 'Groups'} },
            { name: 'email.campaign', path: 'campaign/:id', component: ()=> import('./components/Email/Campaign'), props: true, meta: {breadcrumb: 'Campaign'} },
            { name: 'email.campaigns', path: 'campaign', component: ()=> import('./components/Email/Campaigns'), meta: {breadcrumb: 'Campaigns'} },
            { name: 'email.schedule', path: 'schedule/:id', component: ()=> import('./components/Email/Schedule'), props: true, meta: {breadcrumb: 'Schedule'} },
            { name: 'email.schedules', path: 'schedule', component: ()=> import('./components/Email/Schedules'), meta: {breadcrumb: 'Schedules'} },
            { name: 'email.queue', path: 'queue', component: ()=> import('./components/Email/Queue'), meta: {breadcrumb: 'Queue'} },
            { name: 'email.queue.preview', path: 'queue/:queue_id/preview', component: ()=> import('./components/Email/QueuePreview'), props: true, meta: {breadcrumb: 'Queue Preview'} },
            { name: 'email.log', path: 'log/:id', component: ()=> import('./components/Email/LogDetail'), props: true, meta: {breadcrumb: 'Log'} },
            { name: 'email.logs', path: 'log', component: ()=> import('./components/Email/Log'), meta: {breadcrumb: 'Log'} },
        ]
    },
    {
        path: '/profile',
        component: ()=> import('./components/Profile'),
    },
    {
        path: '/user',
        component: ()=> import('./components/User/Users'),
    },
    {
        path: '/user/:user_id',
        props: true,
        component: ()=> import('./components/User/User'),
        children: [
            {
                path: '/',
                redirect: 'general',
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/User/General'),
            },
            {
                path: 'log',
                props: true,
                component: ()=> import('./components/User/Log'),
            }
        ]
    },
    {
        path: '/conference',
        component: ()=> import('./components/Conference/Conferences'),
    },
    {
        path: '/conference/:conference_id',
        props: true,
        component: ()=> import('./components/Conference/Conference'),
        children: [
            {
                path: '/',
                redirect: 'general'
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/Conference/General'),
            },
            {
                path: 'member/',
                props: true,
                component: ()=> import('./components/Conference/Members'),
            },
            {
                path: 'member/:conference_member_id',
                props: true,
                component: ()=> import('./components/Conference/Member'),
            },
            {
                path: 'role/',
                props: true,
                component: ()=> import('./components/Conference/Roles'),
            },
            {
                path: 'role/:conference_role_id',
                props: true,
                component: ()=> import('./components/Conference/Role'),
            },
        ],
    },
    {
        path: '/district',
        component: ()=> import('./components/District/Districts'),
    },
    {
        path: '/district/:district_id/church/:church_id',
        props: true,
        component: ()=> import('./components/Report/Premade/Congregation'),
    },

    {
        path: '/district/:district_id',
        props: true,
        component: ()=> import('./components/District/District'),
        children: [
            {
                path: '/',
                redirect: 'general'
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/District/General'),
            },
            {
                path: 'region',
                props: true,
                component: ()=> import('./components/District/Region'),
            },
            {
                path: 'member/',
                props: true,
                component: ()=> import('./components/District/Members'),
            },
            {
                path: 'member/:district_member_id',
                props: true,
                component: ()=> import('./components/District/Member'),
            },
            {
                path: 'role/',
                props: true,
                component: ()=> import('./components/District/Roles'),
            },
            {
                path: 'role/:district_role_id',
                props: true,
                component: ()=> import('./components/District/Role'),
            },
            
        ]
    },
    {
        path: '/church',
        component: ()=> import('./components/Church/Churches'),
    },
    {
        path: '/church/find',
        props: true,
        component: ()=> import('./components/Church/Find'),
    },
    {
        path: '/church/:church_id',
        props: true,
        component: ()=> import('./components/Church/Church'),
        children: [
            {
                path: '/',
                redirect: 'general'
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/Church/General'),
            },
            {
                path: 'link/',
                props: true,
                component: ()=> import('./components/Church/Link'),
            },
            {
                path: 'member/',
                props: true,
                component: ()=> import('./components/Church/Members'),
            },
            {
                path: 'member/:church_member_id',
                props: true,
                component: ()=> import('./components/Church/Member'),
            },
            {
                path: 'role/',
                props: true,
                component: ()=> import('./components/Church/Roles'),
            },
            {
                path: 'role/:church_role_id',
                props: true,
                component: ()=> import('./components/Church/Role'),
            },
        ]
    },
    {
        path: '/group',
        component: ()=> import('./components/Group/Groups'),
    },
    {
        path: '/group/:group_id',
        props: true,
        component: ()=> import('./components/Group/Group'),
        children: [
            {
                path: '/',
                redirect: 'general'
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/Group/General'),
            },
            {
                path: 'member/',
                props: true,
                component: ()=> import('./components/Group/Members'),
            },
            {
                path: 'member/:group_member_id',
                props: true,
                component: ()=> import('./components/Group/Member'),
            },
            {
                path: 'preview/',
                props: true,
                component: ()=> import('./components/Group/Preview'),
            },
        ]
    },
    {
        path: '/person',
        component: ()=> import('./components/Person/Persons'),
    },
    {
        path: '/person/:person_id',
        props: true,
        component: ()=> import('./components/Person/Person'),
        children: [
            {
                path: '/',
                redirect: 'general'
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/Person/General'),
            },
            {
                path: 'household',
                props: true,
                component: ()=> import('./components/Person/Household'),
            },
            {
                path: 'membership',
                props: true,
                component: ()=> import('./components/Person/Membership'),
            },
        ],
    },
    {
        path: '/mailing',
        component: ()=> import('./components/Mailing/Mailings'),
    },
    {
        path: '/mailing/:mailing_id',
        props: true,
        component: ()=> import('./components/Mailing/Mailing'),
        children: [
            {
                path: '/',
                redirect: 'general'
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/Mailing/General'),
            },
            {
                path: 'member/',
                props: true,
                component: ()=> import('./components/Mailing/Members'),
            },
            {
                path: 'member/:person_id',
                props: true,
                component: ()=> import('./components/Mailing/Member'),
            },
            {
                path: 'send/',
                props: true,
                component: ()=> import('./components/Mailing/Send'),
            },

        ]
    },
    {
        path: '/report/canned',
        component: ()=> import('./components/Report/Canned/Reports'),
    },
    {
        path: '/report/canned/:canned_report_id',
        props: true,
        component: ()=> import('./components/Report/Canned/Report'),
        children: [
            {
                path: '/',
                redirect: 'general'
            },
            {
                path: 'general',
                props: true,
                component: ()=> import('./components/Report/Canned/General'),
            },
            {
                path: 'view',
                props: true,
                component: ()=> import('./components/Report/Canned/View'),
	        },
            {          
                path: 'test',
                props: true,
                component: ()=> import('./components/Report/Canned/Test'),
            },
            {          
                path: 'classic',
                props: true,
                component: ()=> import('./components/Report/Canned/Classic'),
            }, 
            {          
                path: 'classic-report-view',
                props: true,
                component: ()=> import('./components/Report/Canned/Classic-View'),
            },           
        ],
    },
    {          
        path: '/report/premade/congregation/:church_id',
        props: true,
        component: ()=> import('./components/Report/Premade/Congregation'),
    },
    // {          
    //     path: '/report/premade/superintendent/:district_id',
    //     props: true,
    //     component: ()=> import('./components/Report/Premade/Superintendent'),
    // },

    {          
        path: '/report/premade/district/:district_id',
        props: true,
        component: ()=> import('./components/Report/Premade/District'),
    },    

    {
        path: '/member',
        component: ()=> import('./components/member/Member'),
    },


];

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    scrollBehavior: function(to, from, savedPosition) {
        if (to.hash) {
            return {selector: to.hash}
        } else if(to.name === from.name) {
            return false;
        }
        else {
            return { x: 0, y: 0 }
        }
    },
    routes
});

router.beforeEach((to, from, next) => {

    // Check if authenticated and authorized
    let permission = to.meta.permission;
    if(permission !== undefined && !window.auth.allowed(permission))
    {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    }

    next();
});

export default router;
