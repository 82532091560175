<template>
    <div class="ckeditor">
        <div v-if="isTranslation" class="input-group-prepend flag" :title="translation"><span class="input-group-text"><img :src="'/img/flag/' + $i18n.locale() + '.png'" /></span></div>
        <textarea class="ckeditor-el"></textarea>
    </div>
</template>

<script>

    window.CKEDITOR_BASEPATH = '/assets/ckeditor/';

    require('ckeditor').default;

    CKEDITOR.on( 'instanceReady', function( evt ) {
        evt.editor.dataProcessor.htmlFilter.addRules( {
            elements: {
                img: function(el) {
                    el.addClass('img-responsive');
                }
            }
        });
    });

    CKEDITOR.editorConfig = function(config) {};

    export default {
        name: 'ckeditor',
        data() {
            return {
                ckeditor: null,
            }
        },
        computed: {
            translation() {
                if(this.$i18n.locale() == 'en')
                {
                    return "English Translation";
                }
                else
                {
                    return "Spanish Translation";
                }
            }
        },
        props: {
            value: {type: String, default: () => ``},
            isTranslation: {default: true},
        },
        methods: {
            ckeditorChange() {
                let html = this.ckeditor.getData();
                if(html !== this.value) {
                    this.$emit('input', html);
                }
            }
        },
        watch: {
            value(newVal) {
                if(newVal !== this.ckeditor.getData())
                {
                    this.ckeditor.setData(this.value);
                }
            }
        },
        beforeUpdate () {
            if(this.value !== this.ckeditor.getData())
            {
                this.ckeditor.setData(this.value);
            }
        },
        mounted() {
            this.ckeditor = CKEDITOR.replace($(this.$el).find(".ckeditor-el")[0], {
                extraPlugins: 'uploadimage,filebrowser,font,divarea',
                autoGrow_minHeight: 250,
                allowedContent: true,
                contentsCss: $('#app-css').attr('href'),
                enterMode: CKEDITOR.ENTER_BR,
                imageUploadUrl: '/api/image',
                //filebrowserImageUploadUrl: '/api/image/browser',
                filebrowserImageUploadUrl: '/storage',
                image_prefillDimensions: false,
                entities: false,
            });

            this.$emit('ckeditor', this.ckeditor);

            this.ckeditor.setData(this.value);

            this.ckeditor.on('change', this.ckeditorChange);
        },
        beforeDestroy() {
            if(this.ckeditor)
            {

                setTimeout(() => {
                    this.ckeditor.removeAllListeners();
                    CKEDITOR.instances[this.ckeditor.name].destroy();
                    this.ckeditor.destroy();
                    this.ckeditor = null;

                });
         //       this.ckeditor.focusManager.blur(true);
            }
        }
    }
</script>
